import React, { useState } from 'react'
import { FaTelegram } from 'react-icons/fa'

import CountryPicker from './components/CountryPicker'
import Charts from './components/Charts'
import Cards from './components/Cards'
import useFetch from './useFetch'
import './App.css'

let API_URL = 'https://covid19.mathdro.id/api/'

function App() {
  const [country, setCountry] = useState('Entire World')

  const { data } = useFetch(
    country === 'Entire World' ? API_URL : API_URL + 'countries/' + country
  )
  return (
    <div className="App">
      <div className="headerr">
        <div className="titlee">
          <h1 style={{ fontSize: 32 }}>{country}</h1>
        </div>
        <div>
          <a
            style={{ textDecoration: 'none', color: 'inherit' }}
            href="https://t.me/covid19casesbot"
          >
            <FaTelegram size="30" color="#179CDE" />
          </a>
        </div>
      </div>
      <CountryPicker setCountry={setCountry} />
      <Cards data={data} />
      <Charts data={data} country={country} />
    </div>
  )
}

export default App
