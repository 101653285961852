import React from 'react'
import { Line, Bar } from 'react-chartjs-2'

import useFetch from '../../useFetch'

const URL = 'https://covid19.mathdro.id/api/daily'

const Charts = ({ data, country }) => {
  const BarChart = () => {
    return data ? (
      <Bar
        data={{
          labels: ['Infected', 'Recovered', 'Deaths'],
          datasets: [
            {
              label: 'People',
              backgroundColor: [
                'rgba(0, 0, 255, 0.5)',
                'rgba(0, 255, 0, 0.5)',
                'rgba(255, 0, 0, 0.5)',
              ],
              data: [
                data.confirmed.value,
                data.recovered.value,
                data.deaths.value,
              ],
            },
          ],
        }}
        options={{
          legend: { display: false },
          title: { display: true, text: `Current situation in ${country}` },
        }}
      />
    ) : null
  }

  const LineChart = () => {
    const { data, loading } = useFetch(URL)
    if (country === 'Entire World') {
      return data ? (
        <Line
          data={{
            labels: data.map(({ reportDate }) => reportDate),
            datasets: [
              {
                data: data.map((d) => d.totalConfirmed),
                label: 'Infected',
                borderColor: '#3333ff',
                fill: true,
              },
              {
                data: data.map((d) => d.deaths.total),
                label: 'Deaths',
                borderColor: 'red',
                backgroundColor: 'rgba(255, 0, 0, 0.5)',
                fill: true,
              },
            ],
          }}
          options={{
            title: { display: true, text: `Current situation in ${country}` },
          }}
        />
      ) : null
    }
  }

  return (
    <div className="charts">
      <h1>Charts</h1>
      {country !== 'Entire World' ? <BarChart /> : <LineChart />}
    </div>
  )
}

export default Charts
