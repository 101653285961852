import { useState, useEffect } from 'react'
import axios from 'axios'

const useFetch = (url) => {
  const [state, setState] = useState({
    loading: true,
    data: null,
  })

  useEffect(() => {
    setState((state) => ({ data: state.data, loading: true }))
    axios
      .get(url)
      .then((res) => {
        setState({ data: res.data, loading: false })
      })
      .catch((err) => {
        setState({ data: err, loading: false })
      })
  }, [url])

  return state
}

export default useFetch
