import React from 'react'

import useFetch from '../../useFetch'

const COUNTRY_URL = 'https://covid19.mathdro.id/api/countries'

const CountryPicker = ({ setCountry }) => {
  const { data, loading } = useFetch(COUNTRY_URL)

  return (
    <div className="country-picker">
      <select onChange={(e) => setCountry(e.target.value)}>
        {loading ? (
          <option>Loading please wait ... </option>
        ) : (
          data.countries.map((country, id) => {
            if (id === 0) {
              return (
                <>
                  <option value="Entire World" key="Entire World">
                    Entire World
                  </option>
                  <option value={country.name} key={id}>
                    {country.name}
                  </option>
                </>
              )
            } else {
              return (
                <>
                  <option key={id} value={country.name}>
                    {country.name}
                  </option>
                </>
              )
            }
          })
        )}
      </select>
    </div>
  )
}

export default CountryPicker
