import React from 'react'
import CountUp from 'react-countup'

const Cards = ({ data }) => {
  return (
    <div className="cards">
      <div className="card" style={{ borderBottom: '15px solid #0A79DF' }}>
        <h3 className="gray">Infected</h3>
        {!data ? (
          <h3>Please wait ...</h3>
        ) : (
          <h2>
            <CountUp end={data.confirmed.value} duration={2} />
          </h2>
        )}
        <h3 className="gray">Number of active cases of COVID-19</h3>
      </div>
      <div className="card" style={{ borderBottom: '15px solid #7CEC9F' }}>
        <h3 className="gray">Recovered</h3>
        {!data ? (
          <h3>Please wait ...</h3>
        ) : (
          <h2>
            <CountUp end={data.recovered.value} duration={2} />
          </h2>
        )}{' '}
        <h3 className="gray">Number of recoveries from COVID-19</h3>
      </div>
      <div className="card" style={{ borderBottom: '15px solid #EA425C' }}>
        <h3 className="gray">Deaths</h3>
        {!data ? (
          <h3>Please wait ...</h3>
        ) : (
          <h2>
            <CountUp end={data.deaths.value} duration={2} />
          </h2>
        )}{' '}
        <h3 className="gray">Number of deaths caused by COVID-19</h3>
      </div>
    </div>
  )
}

export default Cards
